function get_articles_by_name(name){
  $.ajax({
    type: 'GET',
    url: '/blog/get_articles_by_name/' + name,
    data: null,
    success: false
  })
}

function filter_order_articles(orderable_type, tag_text){
  orderable_type = orderable_type || 'created_at';
  tag_text = tag_text || 'none';
  $.ajax({
    type: 'GET',
    url: '/blog/filter_order_articles/' + orderable_type + '/' + tag_text,
    data: null,
    success: false
  })
}

function like_article(article_id){
  $.ajax({
    type: 'GET',
    url: '/blog/like_article/' + article_id,
    data: null,
    success: false
  })
}

function get_articles_by_keyword(keyword){
  $.ajax({
    type: 'GET',
    url: '/blog/get_articles_by_keyword/' + keyword,
    data: null,
    success: false
  })
}

function is_article_liked(article_id){

  if($("#like_article").length > 0){
    $.ajax({
      type: 'GET',
      url: '/blog/is_article_liked/' + article_id,
      data: null,
      success: false
    })
  }
}

function toggle_search_article(){
  $.ajax({
    type: 'GET',
    url: '/blog/toggle_search_article',
    data: null,
    success: false
  })
}

function delay(callback, ms) {
  var timer = 0;
  return function() {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}

jQuery(window).on('load', function() {

  is_article_liked($("#article_id").text())

  $('#search_articles').on('keyup', function() {
    get_articles_by_name(this.value)
  })

  $("#get_articles_by_tag").on('change', function() {
    filter_order_articles($("#order_articles").val(), this.value)
  })

  $("#order_articles").on('change', function() {
    filter_order_articles(this.value, $("#get_articles_by_tag").val())
  })

  $("#like_article").on('click', function() {
    like_article($("#article_id").text())
  })

  $("#like_article").on('load', function() {
    is_article_liked($("#article_id").text())
  })

  $('#keyword_input').on("keyup", delay(function (e) {
    get_articles_by_keyword($(this).val())
  }, 200));

  $('.lupa').on('click', function(){
    toggle_search_article()
  })

})